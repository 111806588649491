import React, { useState, useRef } from 'react';
import Papa from 'papaparse';
import { Flex, Flexed } from '../../../utilities/Shared';

const CSVMapper = () => {
  const [csvData, setCsvData] = useState(null);
  const [originalHeaders, setOriginalHeaders] = useState([]);
  const [mappedHeaders, setMappedHeaders] = useState({});
  const [fileName, setFileName] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [recordCount, setRecordCount] = useState(1);
  const fileInputRef = useRef(null);

  const headerOptions = [
    "Ignore Row",
    'prefix',
    'firstName',
    'lastName',
    'middleName',
    'suffix',
    'address1',
    'address2',
    'address3',
    'city',
    'state',
    'zip',
    'walkSequence',
    'carrierRoute',
    'custom 1',
    'custom 2',
    'custom 3',
    'custom 4',
    'custom 5',
    'custom 6',
    'custom 7',
    'custom 8',
    'custom 9',
    'custom 10',
    'custom 11',
     'custom 12',
     'custom 13',
     'custom 14',
     'custom 15',
     'custom 16',
     'custom 17',
     'custom 18',
     'custom 19',
     'custom 20',


  ];

  const processFile = (file) => {
    if (file) {
      setFileName(file.name);
      Papa.parse(file, {
        complete: (result) => {
          const headers = result.data[0];
          setOriginalHeaders(headers);
          setCsvData(result.data);
          const initialMappings = {};
          headers.forEach(header => {
            initialMappings[header] = 'Ignore Column';
          });
          setMappedHeaders(initialMappings);
        },
        header: false,
        skipEmptyLines: true,
      });
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    processFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file && file.type === 'text/csv') {
      processFile(file);
    }
  };

  const handleHeaderChange = (originalHeader, newHeader) => {
    setMappedHeaders(prev => {
      const newMappings = { ...prev };
      const existingHeader = Object.entries(prev).find(
        ([key, value]) => value === newHeader && key !== originalHeader
      );
      if (existingHeader) {
        newMappings[existingHeader[0]] = 'Ignore Column';
      }
      newMappings[originalHeader] = newHeader;
      return newMappings;
    });
  };

  const getUsedHeaders = () => {
    return Object.values(mappedHeaders)
      .filter(header => header !== 'Ignore Column');
  };

  const handleDownload = () => {
    if (!csvData) return;

    const validMappings = Object.entries(mappedHeaders)
      .filter(([_, newHeader]) => newHeader !== 'Ignore Column');
    const mappedHeaderNames = validMappings.map(([_, newHeader]) => newHeader);
    const unmappedHeaders = headerOptions.filter(
      option => option !== 'Ignore Column' && !mappedHeaderNames.includes(option)
    );
    const newHeaders = [...mappedHeaderNames, ...unmappedHeaders];
    
    const headerIndexMap = {};
    validMappings.forEach(([originalHeader]) => {
      headerIndexMap[originalHeader] = originalHeaders.indexOf(originalHeader);
    });

    const mappedData = csvData.slice(1).map(row => {
      const newRow = new Array(newHeaders.length).fill('');
      validMappings.forEach(([originalHeader, newHeader], index) => {
        const originalIndex = headerIndexMap[originalHeader];
        newRow[index] = row[originalIndex] || '';
      });
      return newRow;
    });

    const outputData = [newHeaders, ...mappedData];
    const csv = Papa.unparse(outputData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `mapped_${fileName}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Get first record data (skip header row)
  const getFirstRecordData = (header) => {
    if (!csvData || csvData.length <= 1) return '';
    const headerIndex = originalHeaders.indexOf(header);
    return csvData[recordCount][headerIndex] || '';
  };

  return (
    <div className="csv-mapper">
      <div 
        style={{ 
          marginBottom: '20px',
          border: isDragging ? '2px dashed #4CAF50' : '2px dashed #ccc',
          padding: '20px',
          textAlign: 'center',
          backgroundColor: isDragging ? '#f0fff0' : 'white',
          borderRadius: '4px'
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
        <p>
          {isDragging 
            ? 'Drop CSV file here' 
            : 'Drag and drop CSV file here or click to select'}
        </p>
        <button
          onClick={() => fileInputRef.current.click()}
          style={{
            padding: '8px 16px',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Select File
        </button>
        {fileName && <p style={{ marginTop: '10px' }}>Loaded: {fileName}</p>}
      </div>
<div style={{display:"flex",justifyContent:"space-around"}}>
      {originalHeaders.length > 0 && (
        <div  style={{width: '800px'}}>
          <Flexed> <Flex>Original Headers</Flex> <Flex>First Record</Flex> <Flex>Mapped Header</Flex></Flexed>
          <div>
            {originalHeaders.map((header, index) => {
              const usedHeaders = getUsedHeaders();
              const firstRecordData = getFirstRecordData(header);
              return (
                <div 
                  key={index}
                  style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: '10px',
                    borderBottom:"1px solid grey" 
                  }}
                >
                  <span style={{ flex:"1",width: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {header}
                  </span>
                  <span style={{ flex:"1",width: '150px', color: '#666', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {firstRecordData}
                  </span>
                  <span style={{ flex:"1" }}>
                  <select
                    value={mappedHeaders[header]}
                    onChange={(e) => handleHeaderChange(header, e.target.value)}
                    style={{ 
                      flex: 1, 
                      padding: '5px',
                      backgroundColor: 'white',
                      color: 'black'
                    }}
                  >
                    
                    {headerOptions.map((option, optIndex) => {
                      const isUsed = option !== 'Ignore Column' && 
                                   usedHeaders.includes(option) && 
                                   mappedHeaders[header] !== option;
                      return (
                        <option 
                          key={optIndex} 
                          value={option}
                          style={{
                            color: isUsed ? '#666' : 'black',
                            backgroundColor: isUsed ? '#f0f0f0' : 'white'
                          }}
                        >
                          {option}{isUsed ? ' (in use)' : ''}
                        </option>
                      );
                    })}
                  </select>
                  </span>
                </div>
              );
            })}
          </div>
          
          <button
            onClick={handleDownload}
            style={{ 
              marginTop: '20px', 
              padding: '10px 20px',
              backgroundColor: '#4CAF50',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Download Mapped CSV
          </button>
        </div>
        
      )}
    </div>
    </div>
  );
};

export default CSVMapper;