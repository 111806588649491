import styled from "styled-components";
import Image from '../../../Assets/colorbar.png'
import GripperImg from '../../../Assets/gripper.png'

export const SheetWrap = styled.div`


position:${props => props.position || "relative"};
width:${props => props.width || "0"};
height:${props => props.height || "0"};
border:${props => props.border || "1px solid #303030"};
background:white;
z-index:50;
right:0;
top:0;
  overflow: 'hidden';
transition:all .5s ease-in;
&:hover{
    transform: scale(2);
    right:50px;
    top:-20px;
    
}
`;

export const Tail = styled.div`
position:${props => props.position || "relative"};
width:${props => props.width || "100%"};
height:${props => props.height || ""};
background-color:orange;


`;


export const Colorbar = styled.div`
position:${props => props.position || "relative"};
height:${props => props.height || ""};
background-image:url(${Image});
background-repeat:repeat;
width:100%;
background-size:contain;

`;

export const Colorbarbuff = styled.div`
position:${props => props.position || "relative"};
width:${props => props.width || "100%"};
height:${props => props.height || ""};
background-color:rgb(0,0,0,.2);


`;


export const Content = styled.div`
position:${props => props.position || "relative"};
width:${props => props.width || "100%"};
height:${props => props.height || "calc(100% - 5px - 2.5px)"};
background-color:white;
padding:${props => props.padding || ""};
`;

export const InnerContent = styled.div`
position:${props => props.position || "relative"};
width:${props => props.width || "230px"};
height:${props => props.height || "175px"};
display:flex;
flex-wrap:wrap;
justify-content:space-between;
align-items:center;
border:1px dashed black;
overflow:hidden;
`;

export const Gripper = styled.div`
position:${props => props.position || "relative"};
width:${props => props.width || "100%"};
height:${props => props.height || ""};
background-color:black;
`;




// Step 1: Define Dimensions and Areas
// Sheet Dimensions: 18.75 (width) x 24.75 (height)
// Total Area: 
// 18.75
// ×
// 24.75
// =
// 464.0625
// 18.75×24.75=464.0625 square units
// Piece 1 (Large):
// Original: 8.75 x 11.5
// Rotated: 11.5 x 8.75
// Area: 
// 8.75
// ×
// 11.5
// =
// 100.625
// 8.75×11.5=100.625 square units
// Piece 2 (Small):
// Original: 4.25 x 6.25
// Rotated: 6.25 x 4.25
// Area: 
// 4.25
// ×
// 6.25
// =
// 26.5625
// 4.25×6.25=26.5625 square units
// Step 2: Theoretical Maximum (Area-Based)
// Total area available: 464.0625
// If only large pieces: 
// ⌊
// 464.0625
// /
// 100.625
// ⌋
// =
// 4
// ⌊464.0625/100.625⌋=4 (leaves 61.5625 unused)
// If only small pieces: 
// ⌊
// 464.0625
// /
// 26.5625
// ⌋
// =
// 17
// ⌊464.0625/26.5625⌋=17 (leaves 12.5 unused)
// Mixed: We need a combination that maximizes fit, e.g., 
// 𝑛
// ×
// 100.625
// +
// 𝑚
// ×
// 26.5625
// ≤
// 464.0625
// n×100.625+m×26.5625≤464.0625.
// Step 3: Manual Layout Analysis
// Since pieces must fit within the 18.75 x 24.75 bounds without overlapping, let’s try practical layouts with rotation.

// Option 1: Maximize Large Pieces First
// Large Pieces (8.75 x 11.5):
// Width: 
// ⌊
// 18.75
// /
// 8.75
// ⌋
// =
// 2
// ⌊18.75/8.75⌋=2 (17.5 < 18.75, 1.25 left)
// Height: 
// ⌊
// 24.75
// /
// 11.5
// ⌋
// =
// 2
// ⌊24.75/11.5⌋=2 (23 < 24.75, 1.75 left)
// Total: 
// 2
// ×
// 2
// =
// 4
// 2×2=4 pieces
// Area Used: 
// 4
// ×
// 100.625
// =
// 402.5
// 4×100.625=402.5
// Remaining Area: 
// 464.0625
// −
// 402.5
// =
// 61.5625
// 464.0625−402.5=61.5625
// Space: 1.25 x 24.75 (right strip) = 30.9375, and 18.75 x 1.75 (bottom strip) = 32.8125
// Small Pieces in Remaining Space:
// Right strip (1.25 x 24.75): Too narrow for 4.25 or 6.25
// Bottom strip (18.75 x 1.75):
// ⌊
// 18.75
// /
// 4.25
// ⌋
// =
// 4
// ⌊18.75/4.25⌋=4 (17 < 18.75), 
// ⌊
// 1.75
// /
// 6.25
// ⌋
// =
// 0
// ⌊1.75/6.25⌋=0 (no fit)
// Rotated: 
// ⌊
// 18.75
// /
// 6.25
// ⌋
// =
// 3
// ⌊18.75/6.25⌋=3 (18.75 = 18.75), 
// ⌊
// 1.75
// /
// 4.25
// ⌋
// =
// 0
// ⌊1.75/4.25⌋=0 (no fit)
// Total: 4 large, 0 small (no small pieces fit efficiently)
// Option 2: Mix Large and Small with Rotation
// 2 Large Vertical (8.75 x 11.5):
// Width: 17.5
// Height: 11.5
// Area: 
// 2
// ×
// 100.625
// =
// 201.25
// 2×100.625=201.25
// Remaining: 
// 18.75
// −
// 17.5
// =
// 1.25
// 18.75−17.5=1.25 x 24.75 (right), and 18.75 x (24.75 - 11.5) = 18.75 x 13.25 (below)
// Small Pieces Below (18.75 x 13.25):
// ⌊
// 18.75
// /
// 4.25
// ⌋
// =
// 4
// ⌊18.75/4.25⌋=4 (17 < 18.75), 
// ⌊
// 13.25
// /
// 6.25
// ⌋
// =
// 2
// ⌊13.25/6.25⌋=2 (12.5 < 13.25)
// Total: 
// 4
// ×
// 2
// =
// 8
// 4×2=8 small pieces (4.25 x 6.25)
// Area: 
// 8
// ×
// 26.5625
// =
// 212.5
// 8×26.5625=212.5
// Total:
// Pieces: 2 large + 8 small = 10
// Area Used: 
// 201.25
// +
// 212.5
// =
// 413.75
// 201.25+212.5=413.75
// Unused: 
// 464.0625
// −
// 413.75
// =
// 50.3125
// 464.0625−413.75=50.3125 (1.25 x 24.75 = 30.9375 right, 18.75 x 0.75 = 14.0625 bottom)
// Option 3: 3 Large with Rotation
// 3 Large (11.5 x 8.75, rotated):
// Width: 
// ⌊
// 18.75
// /
// 11.5
// ⌋
// =
// 1
// ⌊18.75/11.5⌋=1 (11.5 < 18.75)
// Height: 
// 8.75
// ×
// 3
// =
// 26.25
// >
// 24.75
// 8.75×3=26.25>24.75, so 2 fit (17.5 < 24.75)
// Try 2 rotated (11.5 x 8.75) + 1 unrotated (8.75 x 11.5):
// Row 1: 11.5 x 8.75 (area: 100.625)
// Remaining Width: 
// 18.75
// −
// 11.5
// =
// 7.25
// 18.75−11.5=7.25, Height: 24.75
// Row 2: 8.75 x 11.5 in 7.25 x (24.75 - 8.75 = 16) (width too small)
// 2 Rotated (11.5 x 8.75):
// Width: 11.5
// Height: 17.5
// Area: 
// 2
// ×
// 100.625
// =
// 201.25
// 2×100.625=201.25
// Remaining: 7.25 x 24.75 (right), 18.75 x 7.25 (bottom)
// Small Pieces:
// Bottom (18.75 x 7.25):
// ⌊
// 18.75
// /
// 4.25
// ⌋
// =
// 4
// ⌊18.75/4.25⌋=4, 
// ⌊
// 7.25
// /
// 6.25
// ⌋
// =
// 1
// ⌊7.25/6.25⌋=1 = 4 small (4.25 x 6.25)
// Area: 
// 4
// ×
// 26.5625
// =
// 106.25
// 4×26.5625=106.25
// Total:
// Pieces: 2 large + 4 small = 6
// Area Used: 
// 201.25
// +
// 106.25
// =
// 307.5
// 201.25+106.25=307.5
// Unused: 
// 464.0625
// −
// 307.5
// =
// 156.5625
// 464.0625−307.5=156.5625
// Option 4: Maximize Small Pieces First
// Small Pieces (4.25 x 6.25):
// Width: 
// ⌊
// 18.75
// /
// 4.25
// ⌋
// =
// 4
// ⌊18.75/4.25⌋=4 (17 < 18.75)
// Height: 
// ⌊
// 24.75
// /
// 6.25
// ⌋
// =
// 3
// ⌊24.75/6.25⌋=3 (18.75 < 24.75)
// Total: 
// 4
// ×
// 3
// =
// 12
// 4×3=12 pieces
// Area: 
// 12
// ×
// 26.5625
// =
// 318.75
// 12×26.5625=318.75
// Remaining: 1.75 x 24.75 (right), 18.75 x 6 (bottom)
// Large Pieces:
// Bottom (18.75 x 6): 
// 8.75
// <
// 18.75
// 8.75<18.75, 
// 11.5
// >
// 6
// 11.5>6 (no fit)
// Rotated: 
// 11.5
// >
// 18.75
// 11.5>18.75, 
// 8.75
// >
// 6
// 8.75>6 (no fit)
// Total: 0 large + 12 small
// Area Used: 318.75
// Unused: 
// 464.0625
// −
// 318.75
// =
// 145.3125
// 464.0625−318.75=145.3125
// Step 4: Optimal Combination
// Best Fit: Option 2 (2 large + 8 small) uses 413.75 square units, leaving only 50.3125 unused, which is the most efficient practical layout tested.
// 2 large (8.75 x 11.5) side by side (17.5 x 11.5)
// 8 small (4.25 x 6.25) in 18.75 x 13.25 (4 across x 2 down)
// Final Answer
// 8.75 x 11.5 Pieces: 2
// 4.25 x 6.25 Pieces: 8
// Total Pieces: 10
// Area Used: 413.75 square units (89.2% of 464.0625)
// This layout assumes pieces are placed without gaps within rows and columns, using rotation minimally (small pieces fit unrotated). If you need a different priority (e.g., maximize large pieces), let me know, and I’ll adjust!