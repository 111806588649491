import React, { useContext, useEffect, useState } from 'react'
import { Flex, Flexed, Input, Label, Selectbox } from '../../../utilities/Shared'
import EstContext from '../../../Context/EstContext'
import PaperSelect from '../../../utilities/PaperSelect'
import Sheet from './Sheet'
import { Plus, X } from 'lucide-react'
import { v4 as uuidv4 } from 'uuid';

const Form = ({item,index,option}) => {


const {info,setInfo,paperlist} = useContext(EstContext)
  const [bleed, setBleed] =  useState(.125)
    const [edges, setEdges] =  useState(.125)
    const [tail, setTail] =  useState(.125)
    const [colorbar, setColorbar] =  useState(.250)
    const [colorbarbuff, setColorbarbuff] =  useState(.125)
    const [gripper, setGripper] =  useState(.4375)

     const [sheetcolorbarbuff, setSheetcolorbarbuff] =  useState(colorbarbuff)
     const [totalsheetwidth ,setTotalsheetwidth] =  useState(0)
     const [totalsheetlength ,setTotalsheetlength] =  useState(0)
     const [totalpiece ,setTotalpiece] =  useState({})
     // digital margin 0.375

  
             useEffect(() => {
                 let aa= parseInt(item.parentL) *  parseInt(item.parentW)
                 let bb= parseInt(item.pressL) *  parseInt(item?.pressW)
        let j = aa / bb
    
        const jj = {...info}
        let h = jj.options[option]
        h.forms[index].parentout = parseInt(j)
        setInfo(jj)
        getpieces()
               },[item?.parentL,item?.parentW,item?.pressW,item?.pressL,item?.press])


              
useEffect(() => {
    getpieces()
},[JSON.stringify(item.versions)])

const changeinfo = (e,item) => {
    const jj = {...info}
    let h = jj.options[option]
   h.forms[index][item] = e.target.value
setInfo(jj)
}


const changever = (e,verindex,field) => {
    const jj = {...info}
    let h = jj.options[option]
   h.forms[index].versions[verindex][field] = e.target.value
   h.forms[index].versions[verindex].itemid=uuidv4()
   h.forms[index].versions[verindex].height =  h.forms[index].versions[verindex].length
setInfo(jj)

}

const whatkindofstoack = (id) => {
    let y = paperlist.filter(filt => filt.stockid === id)
    return y[0]?.kind || (<div style={{fontSize:"inherit",color:"rgb(255,0,0,.7)",fontWeight:"bold"}}>Paper Type Not Selected</div>)
    }

const getpieces = () => {
let ver = item.versions
let hh = []
ver.map(version => {
if(parseInt(version.up) <= 0){

    let testAwidth = Math.floor(parseFloat(item.pressW) / (parseFloat(version.width) + (parseFloat(bleed) * 2))) 
    console.log(testAwidth)
    let testAlength = Math.floor(parseFloat(item.pressL) / (parseFloat(version.length) + (parseFloat(bleed) * 2)))
    console.log(testAlength) 
    let testA = testAlength * testAwidth
    console.log(testA)
    let testBwidth = Math.floor(parseFloat(item.pressL) / (parseFloat(version.width) + (parseFloat(bleed) * 2))) 
    console.log(testBwidth)
    let testBlength = Math.floor(parseFloat(item.pressW) / (parseFloat(version.length) + (parseFloat(bleed) * 2))) 
    console.log(testBlength)
    let testB = testBlength * testBwidth
    console.log(testB)

    if(testB > testA){
        hh.push({itemid:uuidv4(),quantity:item.quantity,up:testB,length:version.width,width:version.length,name:version.name,test:"Test B"})  
    }else{ 
        hh.push({itemid:uuidv4(),quantity:item.quantity,up:testA,width:version.width,length:version.length,name:version.name,test:"Test A"})
    }
 
    const jj = {...info}
    let h = jj.options[option]
   h.forms[index].versions= hh
setInfo(jj)
}
})
}


const setpresslengths = () => {

}

const addversion = () => {
    
     const deepCopyObj = JSON.parse(JSON.stringify(info.options[option].forms[0].versions[0]));
  
     deepCopyObj.itemid = uuidv4()
     deepCopyObj.name =`Version ${info.options[option].forms[index]?.versions?.length + 1}`
 
     const jj = {...info}
     let h = [...jj.options[option].forms[index].versions,deepCopyObj]
    jj.options[option].forms[index].versions = h 
  
      setInfo(jj) 
}


const removeversion = (name) => {
console.log(index)
let hh = info.options[option].forms[index].versions.filter(filt => filt.name !== name )
console.log(hh)
const jj = {...info}
jj.options[option].forms[index].versions = hh 
 setInfo(jj) 
}

useEffect(() => {
    getsheetsizes()
},[item.pressL,item.pressW])

const getsheetsizes = () => {
  let l = (info.options[option].forms[index].pressL - tail - colorbar - colorbarbuff - gripper)
   let w  =(info.options[option].forms[index].pressW - edges - edges)

setTotalsheetwidth(w)
setTotalsheetlength(l)

}






  return (
    <Flexed>

<Flex flex="1.5">
<Flexed marginTop="10px">
<Flex flex="2"><Label>Name</Label><br /><Input value={item.name} onChange={e => changeinfo(e,'name')}/></Flex>   
<Flex flex="2"><Label>Press</Label><br />
<Selectbox value={item.press} onChange={e => changeinfo(e,'press')}>
<option value="0">Select Press</option>
<option value="8">Ricoh Color</option>
<option value="4">GL440- 4 Color</option>
<option value="7">Ricoh B&W</option>
<option value="9">Colormax - Envelope</option>
<option value="5">M110  - Folding</option>
<option value="10">Ricoh Oversize</option>
<option value="6">M110  - Sheeter</option>
<option value="3">GL540- 5 Color</option>
</Selectbox></Flex>   
<Flex flex="2"><Label>Method</Label><br />
<Selectbox value={item.method} onChange={e => changeinfo(e,'method')}>
    <option value="">Select Method</option>
    <option value="Sheetwise">Sheetwise</option>
    <option value="Work & Turn">Work & Turn</option>
    <option value="One-Sided" >One-Sided</option>
    <option value="Perfect">Perfect</option>
    <option value="Work & Tumble">Work & Tumble</option>
</Selectbox>
</Flex>  
<Flex><Label>Pages</Label><br /><Input value={item.pages} /></Flex>   
<Flex><Label>Ver's</Label><br /><Input value={item.versions.length} disabled/></Flex>  
</Flexed>

<Flexed marginTop="15px">
<Flex flex="2"><Label>Front Ink's</Label><br /><Input value={item.frontInks} onChange={e => changeinfo(e,'frontInks')}/></Flex>   
<Flex><Label>Front %</Label><br /><Input value={item.frontinkpercent} onChange={e => changeinfo(e,'frontinkpercent')}/></Flex>  
<Flex><Label>Front Coat</Label><br />
<Selectbox  value={item.faq} onChange={e => changeinfo(e,'faq')}>
<option value="">Select Coating 1</option>
<option value="None">None</option>
   <optgroup label="Flood">
		<option value="FGAQ">F Gloss AQ</option>
		<option value="FDAQ">F Dull AQ</option>
		<option value="FSTAQ">F Soft Touch AQ</option>
		<option value="FPAQ">F Pearl AQ</option>
		<option value="FSAQ">F Satin AQ</option>
		<option value="FGUV">F Gloss UV</option>
		<option value="FDUV">F Dull UV</option>
<option value="FCC">F Clear Coat</option>
   </optgroup>
    <optgroup label="Spot">
		<option value="SGAQ">S Gloss AQ</option>
		<option value="SDAQ">S Dull AQ</option>
		<option value="SSTAQ">S Soft Touch AQ</option>
		<option value="SPAQ">S Pearl AQ</option>
		<option value="SSAQ">S Satin AQ</option>
		<option value="SGUV">S Gloss UV</option>
		<option value="SDUV">S Dull UV</option>
<option value="SCC">S Clear Coat</option>
   </optgroup>
    <optgroup label="Strike Thru">
		<option value="STGAQ">ST Gloss AQ</option>
		<option value="STDAQ">ST Dull AQ</option>
		<option value="STSTAQ">ST Soft Touch AQ</option>
		<option value="STPAQ">ST Pearl AQ</option>
		<option value="STSAQ">ST Satin AQ</option>
		<option value="STGUV">ST Gloss UV</option>
		<option value="STDUV">ST Dull UV</option>
<option value="STCC">ST Clear Coat</option>
   </optgroup> 
</Selectbox>
</Flex>   
</Flexed> 

<Flexed marginTop="15px">
<Flex flex="2"><Label>Back Ink's</Label><br /><Input value={item.backInks} onChange={e => changeinfo(e,'backInks')}/></Flex>   
<Flex><Label>Back %</Label><br /><Input value={item.backinkpercent} onChange={e => changeinfo(e,'backinkpercent')}/></Flex>  
<Flex><Label>Back Coat</Label><br />
<Selectbox value={item.baq} onChange={e => changeinfo(e,'baq')}>
<option value="">Select Coating 1</option>
<option value="None">None</option>
   <optgroup label="Flood">
		<option value="FGAQ">F Gloss AQ</option>
		<option value="FDAQ">F Dull AQ</option>
		<option value="FSTAQ">F Soft Touch AQ</option>
		<option value="FPAQ">F Pearl AQ</option>
		<option value="FSAQ">F Satin AQ</option>
		<option value="FGUV">F Gloss UV</option>
		<option value="FDUV">F Dull UV</option>
<option value="FCC">F Clear Coat</option>
   </optgroup>
    <optgroup label="Spot">
		<option value="SGAQ">S Gloss AQ</option>
		<option value="SDAQ">S Dull AQ</option>
		<option value="SSTAQ">S Soft Touch AQ</option>
		<option value="SPAQ">S Pearl AQ</option>
		<option value="SSAQ">S Satin AQ</option>
		<option value="SGUV">S Gloss UV</option>
		<option value="SDUV">S Dull UV</option>
<option value="SCC">S Clear Coat</option>
   </optgroup>
    <optgroup label="Strike Thru">
		<option value="STGAQ">ST Gloss AQ</option>
		<option value="STDAQ">ST Dull AQ</option>
		<option value="STSTAQ">ST Soft Touch AQ</option>
		<option value="STPAQ">ST Pearl AQ</option>
		<option value="STSAQ">ST Satin AQ</option>
		<option value="STGUV">ST Gloss UV</option>
		<option value="STDUV">ST Dull UV</option>
<option value="STCC">ST Clear Coat</option>
   </optgroup> 
</Selectbox>
</Flex>   
</Flexed>
<Flexed >
  
    <Flex>{
   item.versions?.map((ver,verindex) => {
       return  <Flexed marginTop="15px">
        {
          verindex === 0 ? <Flex flex=".5" textAlign="center" padding="3px 0px 0px 0px" onClick={e => addversion()}> <Plus color="green" size="20" strokeWidth={2}/><br />Version</Flex >:<Flex flex=".5" padding="3px 0px 0px 0px">&nbsp;</Flex>
        }
<Flex><Label>Name</Label><br /><Input value={ver.name} onChange={e => changever(e,verindex,'name')}/></Flex> 
<Flex><Label>Quantity</Label><br /><Input value={ver.quantity} onChange={e => changever(e,verindex,'quantity')}/></Flex> 
<Flex><Label>Piece W</Label><br /><Input value={ver.width} onChange={e => changever(e,verindex,'width')}/><br /></Flex>  
<Flex><Label>Piece L</Label><br /><Input value={ver.length} onChange={e => changever(e,verindex,'length')}/><br /></Flex>  
<Flex><Label># Up</Label><br /><Input value={ver.up} onChange={e => changever(e,verindex,'up')}/> </Flex> 
<Flex flex=".01" display="flex" margin="25px 0px 0px 0px" color="red" onClick={e => removeversion(ver.name)}><X size="16"/></Flex>
</Flexed>
   })
}</Flex>
</Flexed>




<Flexed marginTop="10px">  

    <Flex flex="5"><Label>Paper</Label><br />
<PaperSelect list={paperlist} listid="paperlist" value={item.papername} index={index} option={option} />
       <span style={{fontSize:"calc(1.2rem + .2vw)"}}>{whatkindofstoack(item?.paperid)}</span>
</Flex>
<Flex><Label>ID</Label><br /><Input value={item.paperid}/></Flex>  
</Flexed>









</Flex>

<Flex padding="0"  style={{position:"relative",border:"1px solid black",minWidth:"400px",minHeight:"280px",maxWidth:"600px",maxHeight:"560px",display:"flex",alignItems:"center",justifycontent:"center",flexDirection:"column"}}>
        <Sheet versions={item.versions} pieces={totalpiece} sheetcolorbarbuff={sheetcolorbarbuff} setSheetcolorbarbuff={setSheetcolorbarbuff} totalsheetwidth={totalsheetwidth} setTotalsheetwidth={setTotalsheetwidth} length={item.pressL} width={item.pressW} totalsheetlength={totalsheetlength} setTotalsheetlength={setTotalsheetlength}  tail={tail} colorbar={colorbar} colorbarbuff={colorbarbuff} edges={edges} gripper={gripper} bleed={bleed}/>
        <Flexed>
            <Flex style={{fontSize:"calc(1.1rem + .2vw)",margin:"8px 0px",textAlign:"center"}}>{totalsheetlength} X {totalsheetwidth}</Flex>
        </Flexed>
        <Flexed marginTop="10px">
            <Flex><Label>Parent L</Label><br /><Input value={item.parentL} onChange={e => changeinfo(e,'parentL')}/></Flex>  
        <Flex><Label>Parent W</Label><br /><Input value={item.parentW} onChange={e => changeinfo(e,'parentW')}/></Flex>  
        <Flex><Label>Parent Out</Label><br /><Input color={item.parentout === 0 || !item.parentout ? "red" : "inherit"} fontWeight={item.parentout > 0 ? "inherit" : "bold"} value={item.parentout} onChange={e => changeinfo(e,'parentout')}/></Flex> 
         </Flexed>
         <Flexed marginTop="10px">
         <Flex><Label>Press L</Label><br /><Input value={item.pressL} onChange={e => changeinfo(e,'pressL')}/></Flex>  
         <Flex><Label>Press W</Label><br /><Input value={item.pressW} onChange={e => changeinfo(e,'pressW')}/></Flex>  
         <Flex><Label>/Form X</Label><br /><Input value={item.forms} onChange={e => changeinfo(e,'forms')}/></Flex> 
         </Flexed>
      
        <Flexed marginTop="10px" >
        <Flex><Label>Bleed</Label><br /><Input value={bleed}  onChange={e => setBleed(e.target.value)}/></Flex>  
            <Flex><Label>Edges</Label><br /><Input value={edges} disabled/></Flex>   
            <Flex><Label>Tail</Label><br /><Input value={tail} disabled/></Flex> 
            <Flex><Label>Colorbar</Label><br /><Input type="number"  value={colorbar}  disabled/></Flex>   
 
        </Flexed>
    </Flex>

            </Flexed>
  )
}

export default Form






{/*  */}