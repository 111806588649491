import React, { useContext, useEffect, useState } from 'react'
import { Flex, Flexed, Input, Label, Selectbox, Textauto } from '../../../utilities/Shared'
import Sheet from './Sheet'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import OptionForm from './OptionForm'
import {Plus, X} from 'lucide-react'
import { v4 as uuidv4 } from 'uuid';
import EstContext from '../../../Context/EstContext'
import Select from '../../../utilities/Select'
import PaperSelect from '../../../utilities/PaperSelect'
import Form from './Form'
import CSVMapper from './CSVMapper'



const FormSetup = ({form,setForms,option,item}) => {

    const {info, setInfo,paperlist,forms} = useContext(EstContext)
  

const addform = () => {
 const deepCopyObj = JSON.parse(JSON.stringify(info.options[option].forms[0]));
 deepCopyObj.itemid = uuidv4()
 deepCopyObj.name =`Form ${info.options[option].forms.length + 1}`
 const jj = {...info}
 let h = [...jj.options[option].forms,deepCopyObj]
jj.options[option].forms = h    
    setInfo(jj) 
}

const removeform = (id) => {
  const jj = {...info}
  let h = jj.options[option].forms
  let hh = h.filter(filt => filt.itemid !== id)
  jj.options[option].forms = hh
setInfo(jj)
}

  return (
  <>

<Tabs>
 <TabList> 
     <span style={{position:"relative",top:"10px",marginRight:"10px",textAlign:"left"}}  ><Plus color="green" size="24" strokeWidth={3} onClick={e => addform()}/></span>
    {
        item.forms?.map((item,index) => {
return <Tab><div style={{fontSize:"calc(1rem + .2vw)"}}>{item.name}
{
    index > 0 ? <X size="20" strokeWidth={3} style={{position:"relative",right:"-7px",top:"6px"}} color="rgb(255,0,0,.6)" onClick={e => removeform(item.itemid)} /> : <X size="20" strokeWidth={3} style={{position:"relative",right:"-7px",top:"6px",visibility:"hidden"}} />
}
</div></Tab>
        })
    }
    </TabList>
    {
        item?.forms.map((item,itemindex) => {
        return    <TabPanel style={{color:"#418DA7"}}><Form item={item} index={itemindex} option={option}/></TabPanel>  
        })
    }
     </Tabs>


<br />
          
{/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
<Flexed>
            <Flex fontSize="calc(1.3rem + .2vw)" >
         <div style={{border:"2px solid #BCDDA0",fontSize:"calc(1.2rem + .2vw)",padding:"10px"}}>  8.500 X 11.000 running 3 up on a 14.000 x 28.000 sheet<br />
0 Form(s) - 1 Version(s)<br />
Sheetwise on Ricoh Color with 1 passe(s)<br />
2 out on a 28.000 x 40.000 sheet<br />
100# Anthem Matte Cover<br />
C,M,Y,K<br />
Qty1 - 100 / 3 up * forms * 1 versions = 0 + 5% = 0<br />
0 + 0 M/R = 0<br />
Add'l - 0 / 3 up * forms * 1 versions = 0 + 5% = 0<br />
</div>
</Flex>
<Flex fontSize="calc(1.3rem + .2vw)" >
             <div style={{padding:"12px 30px",border:"2px solid #BCDDA0"}}>
                
            <Flexed fontSize="calc(1.1rem + .2vw)" fontWeight="bold" borderbottom="1px solid black">
    <Flex ></Flex>
    <Flex >HRS</Flex>
    <Flex >100</Flex>
    <Flex ></Flex>
</Flexed>
            <Flexed fontSize="calc(1.1rem + .2vw)" fontWeight="bold" borderbottom="1px solid black">
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Press</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
            <Flexed fontSize="calc(1.1rem + .2vw)" fontWeight="bold" borderbottom="1px solid black">
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Inks</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Wash-Up</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Blanket</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Cuts</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Folds</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Die Cutting</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Drilling</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Duplo</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>O Corner</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Hand Work</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Additional</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Total</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
</div> 
     </Flex>
    </Flexed>
        <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />

        
    <div>
      <CSVMapper />
    </div>
        </>


  )
}

export default FormSetup







/*
nate - 640f438fddce01cbed2ee067
pam - 640f438fddce01cbed2ee06d
celeste - 640f438fddce01cbed2ee0bb
jj - 67a4d5a74deb20b6115a7f37
thom - 640f438fddce01cbed2ee08c
meredith - 640f438fddce01cbed2ee06e
paul - 6580869bd52aa02c41a3f435




kelly - 640f438fddce01cbed2ee0b4

*/