import React, { useContext, useEffect, useState } from 'react'
import { Input } from './Shared'
import EstContext from '../Context/EstContext'


const PaperSelect = ({list,value,listid,placeholder,required,border,disabled,MB,option,index}) => {

    const {info,paperlist,setInfo} = useContext(EstContext)


    
    //    //setInfo(jj)

    const setitem = async(e) => {
        console.log(index)
     let inputValue = e.target.value;

let id;
   let stockid
   let weight
   const jj = {...info}
   let h = jj.options[option]
var options = document.getElementById(listid).childNodes;
for (var i = 0; i < options.length; i++) {
                  if (options[i].value === inputValue) {
                  id = (options[i].dataset.item)
                  stockid = (options[i].dataset.stockid)
                  weight = (options[i].dataset.weight)
                  }}
if(id){

   
    h.forms[index].papername = inputValue
    h.forms[index].paperstockid = id
    h.forms[index].paperid = stockid
    h.forms[index].weight = weight
    setInfo(jj)
}else{
    h.forms[index].papername = inputValue
    h.forms[index].paperstockid = ""
    h.forms[index].paperid = ""
    h.forms[index].weight = ""
    setInfo(jj)
}




//      
//               itemindex = (options[i].dataset.itemindex)
//               }}
//               if(id){
//                 setValue({id,name:inputValue,stockid,itemindex})
      
//               }else{
//                 setValue({id:null,name:inputValue,stockid,itemindex})
//               }
             
            }


  return (
   <>
   <Input marginBottom={MB} type="text" border={border} placeholder={placeholder} value={value} list={listid} onChange={e => setitem(e,index)} />

   <datalist id={listid}>
{
    list?.map((item,index) => {
     return   <option key={index} data-item={item._id} data-index={index} data-stockid={item.stockid} data-weight={item.weight} value={item.stockid > 0 ? `${item.name} - ${item.kind}` : item.name} /> 
    })
}
   </datalist>
   </>
  )
}

export default PaperSelect