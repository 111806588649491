import React, { useState, useEffect, useMemo } from 'react';
import './PieceFitter.css';
import { Flex, Flexed } from '../../../utilities/Shared';
import {Colorbar, Gripper, SheetWrap, Tail} from './EstimatesStyles'
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const Sheet = ({ versions, totalsheetwidth, totalsheetlength,tail,colorbar,colorbarbuff,gripper,bleed,length,width }) => {
  const SHEET_WIDTH = Number(totalsheetwidth) * 10;
  const SHEET_HEIGHT = Number(totalsheetlength) * 10;
  const [items, setItems] = useState([]);
  const [layout, setLayout] = useState([]);
  const [spin, setSpin] = useState(false);

  const debouncedVersions = useDebounce(versions, 300);
  const debouncedSheetWidth = useDebounce(SHEET_WIDTH, 300);
  const debouncedSheetHeight = useDebounce(SHEET_HEIGHT, 300);
console.log(totalsheetwidth)
console.log(totalsheetlength)
console.log(width)
console.log(length)
  const scaleup = (num) => Number(num) * 10;

  const setnewwidth = (width) => {
   return (parseFloat(width) + parseFloat(bleed) + parseFloat(bleed))
  }
  const setnewlength = (length) => {
    return (parseFloat(length) + parseFloat(bleed) + parseFloat(bleed))
   }

console.log(items)
  const getitems = () => {
    let h = debouncedVersions?.map((item, index) => ({
      id: index,
      name: item.name,
      width: scaleup(setnewwidth(item.width)),
      height:scaleup(setnewlength(item.height)),
      up: Number(item.up) || 0,
    }));
    setItems(h);
    setSpin(false);
  };

  useEffect(() => {
    setSpin(true);
    getitems();
  }, [JSON.stringify(versions), debouncedSheetWidth, debouncedSheetHeight,bleed]);

  const isValidPosition = (newItem, currentLayout, excludeInstanceId = null) => {
    const { x, y, width, height } = newItem;
    if (x < 0 || y < 0 || x + width > SHEET_WIDTH || y + height > SHEET_HEIGHT) {
      return false;
    }
    for (const item of currentLayout) {
      if (item.instanceId === excludeInstanceId) continue;
      if (
        x < item.x + item.width &&
        x + width > item.x &&
        y < item.y + item.height &&
        y + height > item.y
      ) {
        return false;
      }
    }
    return true;
  };

  const calculatedLayout = useMemo(() => {
    const newLayout = [];

    const placeItem = (item, instanceId) => {
      console.log(item)
      for (let y = 0; y <= SHEET_HEIGHT - item.height; y += 1) {
        for (let x = 0; x <= SHEET_WIDTH - item.width; x += 1) {
          const newItem = { ...item, x, y, instanceId };
          if (isValidPosition(newItem, newLayout)) {
            newLayout.push(newItem);
            return true;
          }
        }
      }
      console.warn(`${item.name}-${instanceId.split('-')[1]} could not be placed on the sheet`);
      return false;
    };

    items?.forEach((item) => {
      for (let i = 0; i < item.up; i++) {
        const instanceId = `${item.id}-${i}`;
        const placed = placeItem(item, instanceId);
        if (!placed) {
          console.warn(`${item.name}-${i} could not be placed on the sheet`);
        }
      }
    });

    return newLayout;
  }, [items, SHEET_WIDTH, SHEET_HEIGHT]);

  useEffect(() => {
    setLayout(calculatedLayout);
  }, [calculatedLayout]);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const handleQuantityChange = debounce((id, delta) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id && item.up + delta >= 1
          ? { ...item, up: item.up + delta }
          : item
      )
    );
  }, 300);

  const handleDragStart = (e, instanceId) => {
    e.dataTransfer.setData('instanceId', instanceId);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const instanceId = e.dataTransfer.getData('instanceId');
    const rect = e.target.getBoundingClientRect();
    const newX = Math.max(0, Math.min(e.clientX - rect.left, SHEET_WIDTH));
    const newY = Math.max(0, Math.min(e.clientY - rect.top, SHEET_HEIGHT));

    setLayout((prevLayout) => {
      const draggedItem = prevLayout.find((item) => item.instanceId === instanceId);
      const updatedItem = { ...draggedItem, x: newX, y: newY };

      if (isValidPosition(updatedItem, prevLayout, instanceId)) {
        return prevLayout.map((item) =>
          item.instanceId === instanceId ? updatedItem : item
        );
      } else {
        console.log('Invalid drop position - overlaps or out of bounds');
        return prevLayout;
      }
    });
  };

  const handleDragOver = (e) => e.preventDefault();

  const getPlacedCount = (itemId) => {
    return layout.filter((layoutItem) => layoutItem.id === itemId).length;
  };

  console.log(width)
  console.log(length)
  return (
    <>
     

     <SheetWrap width={`${scaleup(width)}px`} height={`${scaleup(length)}px`}>
      <div style={{minHeight:"5px"}}>
    <Tail height={`${scaleup(tail)}px`}/>
     <Colorbar height={`${scaleup(colorbar)}px`}/>
     </div>
        <div
          className="sheet"
          style={{
            width: `${SHEET_WIDTH}px`,
            height: `${SHEET_HEIGHT}px`,
          }}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          {layout.map((item) => (
            <div
              key={item.instanceId}
              className="item"
              draggable
              onDragStart={(e) => handleDragStart(e, item.instanceId)}
              style={{
                width: `${item.width}px`,
                height: `${item.height}px`,
                left: `${item.x}px`,
                top: `${item.y}px`,
                position: 'absolute',
                color: 'black',
                backgroundColor: getColor(item.id),
              }}
            >
              {item.name}
            </div>
          ))}
        </div>
    <Gripper height={`${scaleup(gripper)}px`}/>
      </SheetWrap>


      <div style={{padding:"6px 0px"}}>
        {versions.map((item, index) => (
          <Flexed key={index} style={{ fontSize: 'calc(1rem + .2vw)' }}>
            <Flex
              color={getPlacedCount(index) < item.up ? 'red' : 'black'}
            >{`${item.name} - ${setnewwidth(item.width).toFixed(2)} X ${ setnewwidth(item.length).toFixed(2)} has ${getPlacedCount(index)} item(s) placed`}</Flex>
          </Flexed>
        ))}
      </div>
    </>
  );
};

const getColor = (id) => {
  const colors = {
    0: '#ff9999',
    1: '#99ff99',
    2: '#9999ff',
    3: '#ffcc99',
  };
  return colors[id] || '#cccccc';
};

export default Sheet;