import styled from 'styled-components'
import DatePicker from "react-datepicker";
import TextareaAutosize from 'react-textarea-autosize';

export const Wrapper = styled.div`
width:100%;
color:black;
position:${ props => props.position || "relative"};
height:""


`;

export const Flexed = styled.div`
display: ${props => props.display || "flex"};
justify-content: ${props => props.justifycontent || ""};
flex-direction: ${props => props.flexDirection || "row"};
align-items: ${props => props.alignItems || "flex-start"};
position: ${props => props.position || "relative"};
width: ${props => props.width || "100%"};
padding: ${props => props.padding || "0px"};
background-color: ${props => props.bgColor || ""};
border: ${props => props.border || ""};
gap: ${props => props.gap || "5px"};
font-size:${props => props.fontSize || "inherit"};
font-weight:${props => props.fontWeight || ""};
margin: ${props => props.margin || "0px"};
height: ${props => props.height || ""};
border-bottom: ${props => props.borderBottom || ""};
border-bottom: ${props => props.borderbottom || ""};
border-top: ${props => props.borderTop || ""};
color: ${props => props.color || ""};
padding: ${props => props.padding || "0px"};
top: ${props => props.top || ""};
bottom: ${props => props.bottom || ""};
right:${props => props.right || ""};
text-align: ${props => props.textAlign || ""};
letter-spacing:${props => props.letterSpacing || ""};
margin-bottom:${props => props.marginbottom || "0px"};
margin-top:${props => props.marginTop || ""};

flex-wrap:${props => props.flexWrap || ""};
&:hover{
  background:${props => props.hoverbgcolor || ""};
  color:${props => props.hovercolor || ""};

}

`;

export const Flex = styled.div`
flex: ${props => props.flex || "1"};
display: ${props => props.display || ""};
justify-content: ${props => props.justifycontent || ""};
align-items: ${props => props.alignItems || ""};
text-align: ${props => props.textAlign || ""};
height: ${props => props.height || ""};
padding: ${props => props.padding || "0px 3px"};
position:${props => props.position || "relative"};
border: ${props => props.border || ""};
background-color: ${props => props.bgColor || ""};
color: ${props => props.color || "inherit"};
border-bottom: ${props => props.borderBottom || ""};
border-right: ${props => props.borderRight || ""};
border-left: ${props => props.borderLeft || ""};
border-top: ${props => props.borderTop || ""};
border-bottom: ${props => props.borderBottom || ""};
min-width:${props => props.minWidth || ""};
max-width:${props => props.maxWidth || ""};
font-size:${props => props.fontSize || "inherit"};
font-weight:${props => props.fontWeight || ""};
margin: ${props => props.margin || "0px"};
word-break:${props => props.wordBreak || "break-all"};
white-space:${props => props.whiteSpace || ""};
min-height:${props => props.minHeight || ""};
top:${props => props.top || ""};

`;

export const MainWrap = styled.div`
width:var(--main);
position:relative;
padding:${props => props.padding || "80px 40px 10px 10px"};
height:100%;
left:${props => props.left || 'var(--sideNav)'};
`;

export const Wrap = styled.div`
border:${props => props.border || ""};
position:relative;
width:${props => props.width || "100%"};
padding:${props => props.padding || "0px 20px"};
float:${props => props.float || ""};
background-color:${props => props.bgcolor || "#ECF0F1"};
margin:${props => props.margin || ""};
`;

export const Input = styled.input`
position:${props => props.position || "relative"};
border-radius:5px;
max-width:${props => props.maxWidth || ""};
width: ${props => props.width || "100%"};
border:${props => props.border || "1px solid rgb(0,0,0,.3)"};
outline: none;
font-size:${props => props.fontSize || "calc(.9rem + .2vw)"};
height:${props => props.height || "30px"};
padding:${props => props.padding || "6px 0px 6px 4px"};
text-transform: ${props => props.textTransform || ""};
text-align: ${props => props.textAlign || ""};
font-weight:${props => props.fontWeight || ""};
margin-bottom:${props => props.marginBottom || ""};
border-left:${props => props.borderLeft || "10px solid rgba(122, 187, 67, .5)"};
color:${props => props.color || ""};
&:focus{
  border:2px solid var(--plsGreen);
}

`;

export const Img = styled.img`
position: ${props => props.position || "relative"};
width: ${props => props.width || "100%"};
height: ${props => props.height || ""};
min-height: ${props => props.minHeight || ""};
max-height: ${props => props.maxHeight || ""};
max-width: ${props => props.maxWidth || ""};
border-radius: ${props => props.borderRadius || ""};
color: ${props => props.color || ""};
object-fit: cover;
margin:${props => props.margin || ""};

`;

export const Label = styled.label`
position:relative;
font-size:${props => props.fontSize || "calc(.8rem + .5vw)"};
width: ${props => props.height || "100%"};
font-weight:${props => props.fontWeight || "bold"};
color:${props => props.color || "inherit"};
padding:${props => props.padding || "3px 0px 3px 0px"};
`;

export const Selectbox = styled.select`
position:relative;
border-radius:5px;
width: ${props => props.width || "100%"};
border:${props => props.border || "1px solid rgb(0,0,0,.3)"};
outline: none;
font-size:${props => props.fontSize || "calc(.9rem + .2vw)"};
text-transform: ${props => props.textTransform || ""};
border-left:${props => props.borderLeft || "10px solid rgba(122, 187, 67, .5)"};

&:focus{
    border:2px solid var(--plsGreen);

}
`;



export const Tooltip = styled.div`
position:absolute;
width:100%;
display:none;
`;


export const Textarea = styled.textarea`
min-height:100%;
width:100%;
outline:none;
border:1px solid #c8c8c8;
font-size:${props => props.fontSize || "calc(.6rem + .5vw)"};
padding:${props => props.padding || "5px 5px"};
text-transform: ${props => props.textTransform || "capitalize"};
overflow:${props => props.overflow || "hidden"};
resize:none;
:focus{
    border:2px solid var(--plsGreen);
  }

`;


export const Textauto = styled(TextareaAutosize)`
min-height: ${props => props.minHeight || "100px"};
width:100%;
outline:none;
word-wrap: break-word;
display: inline-block;
white-space: pre-wrap;
color: ${props => props.color || "inherit"};
border-radius: 5px 5px 0px 0px;
border: ${props => props.border || "1px solid #418DA7"};
font-size:${props => props.fontSize || "calc(.8rem + .5vw)"};
padding:${props => props.padding || "5px 15px 5px 5px"};
text-transform: ${props => props.textTransform || "capitalize"};
min-height: ${props => props.minHeight || ""};
resize:none;
&:focus{
    border:2px solid var(--plsGreen);
  }


`;

export const Button = styled.button`

padding:${props => props.padding || "6px 25px"};
border:${props => props.border || "none"};
background-color:${props => props.bgcolor || "#C3D2DB"};
color:${props => props.color || "inherit"};
width:${props => props.width || ""};
display: inline-block;
position:${props => props.position || "relative"};
font-weight:${props => props.fontWeight || "normal"};
top:${props => props.top || ""};
right:${props => props.right || ""};
left:${props => props.left || ""};
bottom:${props => props.bottom || ""};
margin:${props => props.margin || ""};
font-size:${props => props.fontSize || ""};
z-index:${props => props.zIndex || ""};
border-radius:${props => props.borderRadius || ""};
white-space:nowrap;
word-wrap: normal;
outline:${props => props.outline || "none"};
&:hover{
  cursor:${props => props.hcursor || "pointer"};
  background-color:${props => props.hbgcolor || "#7ABB43"};
  color:${props => props.hcolor || ""};
}
&:onFocus{
  border:1px solid var(--plsGreen);
  color:red;
}
`;


export const Datepick = styled(DatePicker)`
position:relative;
border-radius:5px;
width: ${props => props.width || "100%"};
border:${props => props.border || "1px solid rgb(0,0,0,.3)"};
outline: none;
border-left:"10px solid rgba(122, 187, 67, .5)";
font-size:calc(1rem + .5vw);
font-weight:${props => props.fontWeight || ""};
padding:8px 5px;
height:30px;

&:focus{
  border:2px solid var(--plsGreen);
}
`;



export const PhoneNumber = (value) => {
if(!value || value === undefined  ){
return value;
    }else{
        value = value.replace(/[^\d]/g, '');
        return `(${value.slice(0,3)}) ${value.slice(3,6)}-${value.slice(6,10)}`;
    }

}

export const currency = (num) => {
 return parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }


 export const Scroll = styled.div`
width:100%;
 overflow-y:auto;
 text-align:${props => props.textAlign || ""};
 height: ${props => props.height || ""};
 min-height: ${props => props.minHeight || ""};
 position:relative;
 margin: ${props => props.margin || "0px"};
 &::-webkit-scrollbar {
  width: 0rem;
  background-color: #0E425A;
  border-radius: 1rem;


}
 `;

 export const TinySquare = styled.div`
 position:relative;
 display:inline-block;
 top:5px;
 height:17px;
 width:17px;
 border-radius:50%;
 background-color:${props => props.bgcolor || "transparent"};
 `;
 
/*
 <button 
 onClick={() => navigator.clipboard.writeText('dfgh')}>
  Copy
 </button>*/

 export const Table = styled.table`
 width:${props => props.width || "100%"};
 font-family: Arial, Helvetica, sans-serif;
 border-collapse: collapse;
 width: 100%;



;

 `;

 export const THead = styled.thead`
 width:100%;
border-right:1px solid lightgrey;
border-left:1px solid lightgrey;
background:white;
gap:${props => props.gap || "5px"};
 `;



 export const TBody = styled.tbody`
 width:100%;
 gap:${props => props.gap || "5px"};
 border:1px solid lightgrey;


 tr:nth-child(odd){
  background:rgb(0,0,0,.1);
}

tr:hover{
  background:${props => props.hbgcolor || ""};

}

 `;

 export const Tr = styled.tr`
 display:flex;
 gap:${props => props.gap || "5px"};
padding:5px;

&:hover > td{
  color:${props => props.hcolor || "#303030"};
}

 `;

 export const Th = styled.th`
 width:100%;
 flex:${props => props.flex || "1"};
 background:white;
 text-align:left;
 font-size:calc(.9rem + .2vw);
 font-weight:bold;
 `;


 export const Td = styled.td`
 width:100%;
flex:${props => props.flex || "1"};
color:${props => props.color || "#303030"};
word-break:${props => props.wordBreak || "break-all"};
font-size:calc(.8rem + .2vw);
background-color:${props => props.bgColor || ""};
padding:${props => props.padding || ""};

 `;


