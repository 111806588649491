import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';

export const EstContext = createContext();

export const EstProvider = ({children}) => {
    const param = useParams()
const RFQ = param.id
const anchor = document.querySelector('#bottom')

const [info, setInfo] = useState({})
const [forms, setForms] = useState([])
const [papers, setPapers] = useState([])
const [pressoptions, setPressoptions] = useState([])
const [paperlist, setPaperlist] = useState([])
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*' 
    }
  };

useEffect(() => {
    const getPapers = async() => {
        await axios.get(`https://highgroundapi.herokuapp.com/paper/all`)
     .then(res => {setPaperlist(res.data)})
     .catch(err => console.log(err))
     }
     getPapers()
},[])


  
   
   const removeform = (e,id) => {
    e.preventDefault()
let oldforms = forms
let newforms = oldforms.filter(filt => filt.itemid !== id)
//anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
setForms(newforms)

   }
 
useEffect(() => {

   

    const getquote = async() => {
        let old = await axios.get(`http://localhost:5003/quotes/${RFQ}`)
        .then(res => {if(res.data.length > 0){console.log('nada')}else{getrfq()}})
        .catch(err => console.log(err))
    }
getquote()

},[])



const getrfq = () => {
    let tr = {
        quote:RFQ
    }
    let old = axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getrfqquote.php`,tr,config)
    .then(res => {rfqsetup(res.data)})
    .catch(err => console.log(err))
}



const rfqsetup = (data) => {



    let newdata ={}
    newdata.customer = data.customer
    newdata.enterdate = data.entered
    newdata.projectname = data.pname
    newdata.quote = data.quote
    newdata.projectstyle = data.projectstyle
    newdata.contact = data.contact
    newdata.salesrep = data.salesrep
    newdata.csr = data.csr
    newdata.duedate = data.qddate
    newdata.pflatw = data.pflatw
    newdata.pflatl = data.pflatl
    newdata.pfinishw = data.pfoldw
    newdata.pfinishl = data.pfoldl
    newdata.pages = data.pagecount
    newdata.totalversions = data.versions
    newdata.coverstyle = data.coverstyle
    
    let tempforms =[]
let options=[]
let quants = data.quantity.split(',')
let quantcount = quants.length
console.log(quantcount)
    if(data.comp1 !== null){
        let h = data.comp1.split(':')
        let a = {
            "itemid":uuidv4(),
                "name":h[0],
                "frontInks":h[8],
                "backInks":h[9],
                "faq":"FGAQ",
                "baq":"FGAQ",
                "versions":[
                    {
                        name:"Version 1",
                        quantity:quants[quantcount - 1],
                        width:h[4],
                        length:h[5],
                    }
                ],
                "pages":"2",
                "forms":"1",
                "pieceW":h[4],
                "pieceL":h[5],
                "finishW":h[6],
                "finishL":h[7],
                "pressW":"36",
                "pressL":"24",
                "parentW":"36",
                "parentL":"24",
                "parentout":"0",
                "frontinkpercent":8,
                "backinkpercent":8,
               "papername":h[1],
              "paperid":h[2],
              "paperweight":h[3],
              "paperstockid":"",
                
               }
     tempforms.push(a)
    }
    if(data.comp1 !== null){
        let h = data.comp1.split(':')
    
        let a = {
            "itemid":uuidv4(),
                "name":h[0],
                "frontInks":h[8],
                "backInks":h[9],
                "faq":"FGAQ",
                "baq":"FGAQ",
                "versions":[
                    {
                        name:"Version 1",
                        quantity:quants[quantcount - 1],
                        width:h[4],
                        length:h[5],
                    }
                ],
                "pages":"2",
                "forms":"1",
                "pieceW":h[4],
                "pieceL":h[5],
                "finishW":h[6],
                "finishL":h[7],
                "pressW":"36",
                "pressL":"24",
                "parentW":"36",
                "parentL":"24",
                "parentout":"0",
                "frontinkpercent":8,
                "backinkpercent":8,
                "papername":"Test",
                "paperid":222,
                "paperweight":80,
                "paperstockid":"",
               }
     tempforms.push(a)
    }
    let h = {itemid:uuidv4(),forms:tempforms}
    options.push(h)
    // options.forms = tempforms
     newdata.options = options

    setForms(tempforms)

    setInfo(newdata)
}



// catalog Imprints:
// 60# Case Gloss Text:
// 53:
// 60:
// 10.5:
// 15.00:
// 10.5:
// 7.5:
// BLACK:
// :
// Yes:
// Yes:
// :
// :
// :
// :
// :
// :
// :
// :
// Sheet Fed:
// No:
// No:
// No:
// :
// 2


// test
// :80# Case Gloss Text
// :127
// :80
// :8.5
// :11
// :8.5
// :11
// :C,M,Y,K
// :K
// :Yes
// :Yes
// :
// :
// :
// :
// :
// :
// :
// :
// :Best Press
// :No
// :No
// :No
// :
// :2




//"Cards:
// 120# Endurance Gloss Cover:
// 185:
// 120:
// 12.5:
// 19:
// 12.5 :
// 19:
// C,M,Y,K:
// C,M,Y,K:
// Yes:
// Yes:
// ::::::::Best Press:No:No:No::2"

/*
${"comp" .$x}['pname'] = $this->compPart[$r];
	${"comp" .$x}['paper'] = $this->paper[$r];
	${"comp" .$x}['papercode'] = $paper['id'];
	${"comp" .$x}['weight'] = $paper['weight'];
	${"comp" .$x}['flatw'] = $this->flatw[$r];
	${"comp" .$x}['flatl'] = $this->flatl[$r];
	${"comp" .$x}['foldw'] = $this->foldw[$r];
	${"comp" .$x}['foldl'] = $this->foldl[$r];
	${"comp" .$x}['fink'] = $this->fink[$r];
	${"comp" .$x}['bink'] = $this->bink[$r];
	${"comp" .$x}['fbleed'] = $this->fbleed[$r];
	${"comp" .$x}['bbleed'] = $this->bbleed[$r];
	${"comp" .$x}['faq'] = $this->inks($this->faq[$r]);
	${"comp" .$x}['baq'] = $this->inks($this->baq[$r]);
	${"comp" .$x}['faqstyle'] = $this->secinks($this->faq[$r]);
	${"comp" .$x}['baqstyle'] = $this->secinks($this->baq[$r]);
	${"comp" .$x}['faq2'] = $this->inks($this->faq2[$r]);
	${"comp" .$x}['baq2'] = $this->inks($this->baq2[$r]);
	${"comp" .$x}['faqstyle2'] = $this->secinks($this->faq2[$r]);
	${"comp" .$x}['baqstyle2'] = $this->secinks($this->baq2[$r]);
	${"comp" .$x}['press'] = $this->runOn[$r];
	${"comp" .$x}['score'] = $this->score[$r];
	${"comp" .$x}['perf'] = $this->perf[$r];
	${"comp" .$x}['diecut'] = $this->diecut[$r];
	${"comp" .$x}['foldstyle'] = $this->foldstyle[$r];
	${"comp" .$x}['pagecount'] = $this->pagecount;
*/

/*
addy1:""
addy2:""
addybindery1:"0.00"
addybindery2:"0.00"
bflatl:"15.00"
bflatw:"10.5"
bfoldl:"7.5"
bfoldw:"10.5"
binderynotes:"Cut, Fold, Stitch and box"
bindon:"10.5"
bindstyle:""
boxcost1:"0.00"
boxcost2:"0.00"
boxcount1:"0"
boxcount2:"0"
boxcountoverride1:"0"
boxcountoverride2:"0"
boxes:"0"
bundlecost1:"0.00"
bundlecost2:"0.00"
bundlein:"0"
bundleprice:"0.00"
bundletype:""
coiling:"0"
coiling1:"0.00"
coiling2:"0.00"
comp1:"catalog Imprints:60# Case Gloss Text:53:60:10.5:15.00:10.5:7.5:BLACK::Yes:Yes:::::::::Sheet Fed:No:No:No::2"
comp2:null
comp3:null
comp4:null
comp5:null
comp6:null
comp7:null
comp8:null
comp9:null
comp10:null
compquantity:"0.00"
contact:""
cost1:"3192.00,3192.00"
cost2:","
coverstyle:"None"
csr:"1"
customer:"Iraq-A-Ribs"
deboss:"0"
delivery1:"0.22"
delivery2:"0.00"
descr:null
descr2:null
descr3:null
diecutcheck:null
digitalmarkup:"0.00000"
drilling:"0.00"
duplo:"0"
emboss:"0"
entered:"2021-08-12 14:59:29"
estimator:""
estnotes:null
estprepressnotes:""
foil:"0"
foldtype:null
id:"14982"
inkjet:null
insertdate:"2021-08-12 14:59:29"
inserting:"No"
insertinto:""
insertnum:"0"
jddate:""
labormarkup:"10.00000"
mailing:"0"
mailingmarkup:"0.00000"
mailingnotes:""
mailprocess:"No"
mailquantity:""
mailsrtyle:""
mainquantity:"0.00"
matchmail:"No"
materialnotes:""
materialsmarkup:"25.00000"
ospmarkup:"20.00000"
ospnotes:null
other:"0"
packagingnotes:""
packtype:"Bulk Cartons"
pagecount:"2"
papermarkup:"15.00000"
partof:null
pdfprepress:""
perfs:""
permit:""
perthousand:"0.00"
perthousand1:"3192.00"
perthousand2:"0.00"
pflatl:"15.00"
pflatw:"10.5"
pfoldl:"7.5"
pfoldw:"10.5"
pname:"Citrus Catalog Imprints - 17"
pnotes:"17 Imprints-Black Ink Only\n17 Version Changes\n10.5 X 15 Folds To 7.5 X 10.5\nBlack/0   1/side Black Plate Change On 1 Form"
pocketfolderbcslits:"1"
pocketfolderdie:"Standard"
pockotfolderpockets:"0"
political:"No"
prepress:"30"
prepressnotes:null
presscheck:"No"
pressnotes:""
prevJob:""
prevQuote:""
priority:"Standard"
projectstyle:"Flyers | Pamphlets | Inserts"
qddate:"08/13/2021"
quantity:"29824"
quote:"28455"
quoteas:"Any"
quotenotes:"Imprints Only Of Shells In Stock (See Quote # 27841)\n\nShells Imprinted 1/0 Black\nCut To 4 Pagers And Fold \n\nStitching On Shell Ticket"
realcost1:"0.00"
realcost2:"0.00"
rfqcity:""
rfqstate:""
rfqzip:"0"
salesrep:"2"
scores:""
shipcust:""
shipdate1:""
shipinst1:""
shipmethod:""
shippingmarkup:"0.00000"
shippingnotes:""
shipvia:""
showonquote:"0"
sinotes:""
special:"No"
specialtext:""
status:"3"
statusstate:"0"
stitchcost1:"0.00"
stitchcost2:"0.00"
stitchhrs2:"0.00"
stitchpasses:"0"
stitchpocketcost:"0.00"
stitchpockets:null
stitchrate:"0.00"
stitchsetup:null
stitchsetupcost:"0.00"
stitchspeed:"0"
stitchtime1:"0.00"
stitchtime2:"0.00"
stitchup:null
tabbing:"No"
tabs:"0"
tdate:"12/22/2023"
threehole:"0.00"
versionnotes:""
versions:"12"
versions text:""
*/














    const value={
        RFQ,
        forms,
        setForms,
        removeform,
        info,
        setInfo,
        paperlist
    
    }


      return (
        <EstContext.Provider value={value}>
            {children}
        </EstContext.Provider>
      )
    }

    export default EstContext