import React, { useState, useEffect, useRef } from 'react';
import './PieceFitter.css';

const PieceFitter = () => {
  const AREA_WIDTH = 350;
  const AREA_HEIGHT = 230;

  const [pieceTypes, setPieceTypes] = useState([]);
  const [placedPieces, setPlacedPieces] = useState([]);
  const [newPiece, setNewPiece] = useState({ width: 50, height: 50, quantity: 1 });
  const areaRef = useRef(null);

  useEffect(() => {
    autoFitPieces();
  }, [pieceTypes]);

  

  console.log()

  const addPieceType = () => {
    if (newPiece.width > 0 && newPiece.height > 0 && newPiece.quantity > 0) {
      const updatedPieceTypes = [{ ...newPiece, id: Date.now() }, ...pieceTypes];
      setPieceTypes(updatedPieceTypes);
      setNewPiece({ width: 50, height: 50, quantity: 1 });
    }
  };

  const updatePieceType = (id, field, value) => {
    const newValue = field === 'quantity' 
      ? Math.max(0, Number(value)) 
      : Math.min(field === 'width' ? AREA_WIDTH : AREA_HEIGHT, Math.max(1, Number(value)));
    
    setPieceTypes(pieceTypes.map(piece => 
      piece.id === id ? { ...piece, [field]: newValue } : piece
    ));
  };

  const removePieceType = (id) => {
    setPieceTypes(pieceTypes.filter(piece => piece.id !== id));
  };

  const autoFitPieces = () => {
    if (!pieceTypes.length) {
      setPlacedPieces([]);
      return;
    }

    const piecesToFit = pieceTypes.map(piece => ({
      ...piece,
      placedCount: 0
    }));

    let newPlacedPieces = [];
    let rows = [];
    let currentRow = { pieces: [], width: 0, height: 0 };
    let totalHeight = 0;

    // Prioritize newest pieces (index 0 is newest)
    for (let pieceType of piecesToFit) {
      for (let i = 0; i < pieceType.quantity; i++) {
        const piece = {
          width: pieceType.width,
          height: pieceType.height,
          id: `${pieceType.id}-${pieceType.placedCount}`,
          typeId: pieceType.id
        };

        if (currentRow.width + piece.width <= AREA_WIDTH && totalHeight + Math.max(currentRow.height, piece.height) <= AREA_HEIGHT) {
          currentRow.pieces.push(piece);
          currentRow.width += piece.width;
          currentRow.height = Math.max(currentRow.height, piece.height);
          pieceType.placedCount++;
        } else if (totalHeight + piece.height <= AREA_HEIGHT) {
          rows.push(currentRow);
          totalHeight += currentRow.height;
          currentRow = { 
            pieces: [piece],
            width: piece.width,
            height: piece.height
          };
          pieceType.placedCount++;
        } else {
          // No more space, stop and remove excess from older pieces
          break;
        }
      }
    }
    if (currentRow.pieces.length) {
      rows.push(currentRow);
      totalHeight += currentRow.height;
    }

    // If we couldn't fit all requested pieces, remove excess from older pieces
    const totalAreaNeeded = piecesToFit.reduce((sum, pt) => 
      sum + (pt.width * pt.height * pt.quantity), 0);
    const availableArea = AREA_WIDTH * AREA_HEIGHT;

    if (totalAreaNeeded > availableArea) {
      let remainingArea = availableArea;
      for (let i = 0; i < piecesToFit.length; i++) {
        const pieceType = piecesToFit[i];
        const pieceArea = pieceType.width * pieceType.height;
        const maxPossible = Math.floor(remainingArea / pieceArea);
        pieceType.placedCount = Math.min(pieceType.quantity, maxPossible);
        remainingArea -= pieceType.placedCount * pieceArea;

        if (remainingArea <= 0) {
          // Set remaining pieces to 0
          for (let j = i + 1; j < piecesToFit.length; j++) {
            piecesToFit[j].placedCount = 0;
          }
          break;
        }
      }
    }

    // Update pieceTypes with actual placed counts
    const updatedPieceTypes = pieceTypes.map((pt, index) => ({
      ...pt,
      quantity: piecesToFit[index].placedCount
    }));
    setPieceTypes(updatedPieceTypes);

    // Rebuild placedPieces based on what fits
    newPlacedPieces = [];
    rows = [];
    currentRow = { pieces: [], width: 0, height: 0 };
    totalHeight = 0;

    for (let pieceType of piecesToFit) {
      for (let i = 0; i < pieceType.placedCount; i++) {
        const piece = {
          width: pieceType.width,
          height: pieceType.height,
          id: `${pieceType.id}-${i}`,
          typeId: pieceType.id
        };

        if (currentRow.width + piece.width <= AREA_WIDTH) {
          currentRow.pieces.push(piece);
          currentRow.width += piece.width;
          currentRow.height = Math.max(currentRow.height, piece.height);
        } else {
          rows.push(currentRow);
          totalHeight += currentRow.height;
          currentRow = { pieces: [piece], width: piece.width, height: piece.height };
        }
      }
    }
    if (currentRow.pieces.length) {
      rows.push(currentRow);
      totalHeight += currentRow.height;
    }

    // Center the pieces
    newPlacedPieces = rows.flatMap((row, rowIndex) => {
      const xOffset = (AREA_WIDTH - row.width) / 2;
      const yOffset = rows.slice(0, rowIndex).reduce((sum, r) => sum + r.height, 0);
      return row.pieces.map((piece, index) => ({
        ...piece,
        x: xOffset + row.pieces.slice(0, index).reduce((sum, p) => sum + p.width, 0),
        y: yOffset
      }));
    });

    const verticalOffset = (AREA_HEIGHT - totalHeight) / 2;
    newPlacedPieces = newPlacedPieces.map(piece => ({
      ...piece,
      y: piece.y + verticalOffset
    }));

    setPlacedPieces(newPlacedPieces);
  };

  const handleDragStart = (piece, e) => {
    const rect = areaRef.current.getBoundingClientRect();
    const offsetX = e.clientX - rect.left - piece.x;
    const offsetY = e.clientY - rect.top - piece.y;

    const handleDrag = (moveEvent) => {
      moveEvent.preventDefault();
      let newX = moveEvent.clientX - rect.left - offsetX;
      let newY = moveEvent.clientY - rect.top - offsetY;

      newX = Math.max(0, Math.min(newX, AREA_WIDTH - piece.width));
      newY = Math.max(0, Math.min(newY, AREA_HEIGHT - piece.height));

      setPlacedPieces(prevPieces => {
        return prevPieces.map(p => 
          p.id === piece.id ? { ...p, x: newX, y: newY } : p
        );
      });
    };

    const handleDragEnd = (endEvent) => {
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', handleDragEnd);

      const finalX = endEvent.clientX - rect.left - offsetX;
      const finalY = endEvent.clientY - rect.top - offsetY;
      const constrainedX = Math.max(0, Math.min(finalX, AREA_WIDTH - piece.width));
      const constrainedY = Math.max(0, Math.min(finalY, AREA_HEIGHT - piece.height));

      const targetPiece = findPieceAtPosition(constrainedX, constrainedY, piece.id);
      
      let updatedPieces;
      if (targetPiece && targetPiece.width === piece.width && targetPiece.height === piece.height) {
        updatedPieces = placedPieces.map(p => {
          if (p.id === piece.id) return { ...p, x: targetPiece.x, y: targetPiece.y };
          if (p.id === targetPiece.id) return { ...p, x: constrainedX, y: constrainedY };
          return p;
        });
      } else {
        updatedPieces = placedPieces.map(p => 
          p.id === piece.id ? { ...p, x: constrainedX, y: constrainedY } : p
        );
      }

      setPlacedPieces(centerPieces(updatedPieces));
    };

    document.addEventListener('mousemove', handleDrag);
    document.addEventListener('mouseup', handleDragEnd);
  };

  const findPieceAtPosition = (x, y, excludeId) => {
    return placedPieces.find(piece => 
      piece.id !== excludeId &&
      x >= piece.x && x < piece.x + piece.width &&
      y >= piece.y && y < piece.y + piece.height
    );
  };

  const centerPieces = (pieces) => {
    if (!pieces.length) return pieces;

    const rows = [];
    let currentRow = [];
    let lastY = -Infinity;

    pieces.sort((a, b) => a.y - b.y || a.x - b.x);
    for (let piece of pieces) {
      if (Math.abs(piece.y - lastY) > 5) {
        if (currentRow.length) rows.push(currentRow);
        currentRow = [piece];
        lastY = piece.y;
      } else {
        currentRow.push(piece);
      }
    }
    if (currentRow.length) rows.push(currentRow);

    let totalHeight = 0;
    const validRows = [];
    for (let row of rows) {
      const rowHeight = Math.max(...row.map(p => p.height));
      if (totalHeight + rowHeight <= AREA_HEIGHT) {
        validRows.push(row);
        totalHeight += rowHeight;
      } else {
        break;
      }
    }

    const centeredPieces = validRows.flatMap((row, rowIndex) => {
      const rowWidth = row.reduce((sum, p) => sum + p.width, 0);
      const xOffset = (AREA_WIDTH - rowWidth) / 2;
      const yOffset = validRows.slice(0, rowIndex).reduce((sum, r) => sum + Math.max(...r.map(p => p.height)), 0);
      
      return row.map((piece, index) => ({
        ...piece,
        x: xOffset + row.slice(0, index).reduce((sum, p) => sum + p.width, 0),
        y: yOffset
      }));
    });

    const verticalOffset = (AREA_HEIGHT - totalHeight) / 2;
    return centeredPieces.map(piece => ({
      ...piece,
      y: piece.y + verticalOffset
    }));
  };

  return (
    <div className="piece-fitter">
      <div className="controls">
        <input
          type="number"
          value={newPiece.width}
          onChange={(e) => setNewPiece({ ...newPiece, width: Math.min(AREA_WIDTH, Math.max(1, Number(e.target.value))) })}
          placeholder="Width"
        />
        <input
          type="number"
          value={newPiece.height}
          onChange={(e) => setNewPiece({ ...newPiece, height: Math.min(AREA_HEIGHT, Math.max(1, Number(e.target.value))) })}
          placeholder="Height"
        />
        <input
          type="number"
          value={newPiece.quantity}
          onChange={(e) => setNewPiece({ ...newPiece, quantity: Math.max(1, Number(e.target.value)) })}
          placeholder="Quantity"
        />
        <button onClick={addPieceType}>Add Piece</button>
      </div>

      <div className="piece-list">
        {pieceTypes.map(piece => (
          <div key={piece.id} className="piece-type">
            <input
              type="number"
              value={piece.width}
              onChange={(e) => updatePieceType(piece.id, 'width', e.target.value)}
              min="1"
              max={AREA_WIDTH}
            />
            <span>x</span>
            <input
              type="number"
              value={piece.height}
              onChange={(e) => updatePieceType(piece.id, 'height', e.target.value)}
              min="1"
              max={AREA_HEIGHT}
            />
            <span>Qty:</span>
            <input
              type="number"
              value={piece.quantity}
              onChange={(e) => updatePieceType(piece.id, 'quantity', e.target.value)}
              min="0"
            />
            <button onClick={() => removePieceType(piece.id)}>Remove</button>
          </div>
        ))}
      </div>

      <div 
        className="area"
        ref={areaRef}
        style={{ 
          width: `${AREA_WIDTH}px`, 
          height: `${AREA_HEIGHT}px`,
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        {placedPieces.map(piece => (
          <div
            key={piece.id}
            className="piece"
            style={{
              width: `${piece.width}px`,
              height: `${piece.height}px`,
              position: 'absolute',
              transform: `translate(${piece.x}px, ${piece.y}px)`,
              backgroundColor: `hsl(${piece.typeId % 360}, 70%, 50%)`,
            }}
            onMouseDown={(e) => handleDragStart(piece, e)}
          >
            {piece.width}x{piece.height}
          </div>
        ))}
      </div>

      <p>Total pieces placed: {placedPieces.length}</p>
    </div>
  );
};

export default PieceFitter;



